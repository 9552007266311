import Layout from "../components/Layout"
import React, { useEffect } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Img from "gatsby-image"

const ThankYou = ({ data }) => {
  const {
    content,
    header_image,
    page_title,
  } = data.allPrismicThankYouPage.edges[0].node.data

  useEffect(() => {
    window.dataLayer.push({
      event: "conversion",
      eventProps: {
        action: "Thank You Page Viewed",
        label: "Thank You Page Viewed",
        value: 1,
      },
    })
  }, [])

  return (
    <Layout>
      <SEO title="Thank You!" />
      <div className="terms-page">
        <div className="terms-page__image-container">
          <h1 className="terms-page__title">{page_title.text}</h1>
          <Img fluid={header_image.fluid} />
        </div>
        <div className="terms-page__content-container">
          <div dangerouslySetInnerHTML={{ __html: content.html }}></div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ThankYouPageQuery {
    allPrismicThankYouPage {
      edges {
        node {
          data {
            content {
              html
            }
            header_image {
              fluid(maxWidth: 1000) {
                src
                srcWebp
                srcSetWebp
                srcSet
                sizes
                base64
                aspectRatio
              }
            }
            page_title {
              text
            }
          }
        }
      }
    }
  }
`

export default ThankYou
